import React, { useEffect, useState } from 'react'
import {
  Animation,
  PageWrapper,
  Flex,
  H1WithBack,
  Separator,
  ItemsGrid,
  TabsContainer,
  Tabs,
  Button,
  P1,
  ContentWrapper,
} from '../../../styling/GlobalStyles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleUser } from 'features/users(Employees)/usersSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { AppDispatch } from 'store'
import moment from 'moment'

/* eslint-disable */
export const SingleEmployee = () => {
  const [active, setActive] = useState('Basic Info')
  const navigate = useNavigate()
  // states
  const dispatch = useDispatch<AppDispatch>()
  const { singleUser = {} } = useSelector((store: any) => store.users)
  const { id } = useParams()

  const [state, setState] = useState({
    contracts: [
      {
        startDate: '',
        endDate: '',
        value: null,
      },
    ],
    id: '',
    firstName: '',
    ssn: '',
    dob: '',
    lastName: '',
    nationalId: '',
    role: '',
    gender: '',
    maritalStatus: '',
    contact: {
      email: '',
      phoneNumber: '',
    },
    drivingLicense: {
      isActive: true,
      drivingCategories: [],
      validUntil: '',
    },
    machineModels: [],
    operations: [],
  })

  useEffect(() => {
    dispatch(getSingleUser(id))
    setState(singleUser)
  }, [])

  useEffect(() => {
    setState(singleUser)
  }, [singleUser])

  const handleSubmit = (e) => {
    e.preventDefault()

    const newMachines = state.machineModels.map((prev) => {
      delete prev.name

      return prev
    })
    const newOperations = state.operations.map((prev) => {
      delete prev.name
      delete prev.description

      return prev
    })
    let newState = { ...state }
    newState = { ...state, machineModels: newMachines, operations: newOperations }

    setState(newState)

    navigate('/employees')
  }

  const renderTabs = () => {
    if (active === 'Basic Info') {
      // return <AddEmployeerBasicInfo setState={setState} state={state} setActive={setActive} />
      return <BasicInfo />
    } else if (active === 'Contract') {
      return <Contract />
    } else if (active === 'Machines') {
      return <Machineries />
    } else if (active === 'Capabilities') {
      return <Capabilities />
    }
  }

  const BasicInfo = () => {
    return (
      <Animation>
        <Flex gap='1rem' column>
          <ItemsGrid columns={3}>
            <TextDisplayCard
              labelText='First Name'
              title={singleUser ? singleUser.firstName : 'N/A'}
            />
            <TextDisplayCard
              labelText='Last Name'
              title={singleUser ? singleUser.lastName : 'N/A'}
            />
            <TextDisplayCard labelText='Phone number' title={singleUser?.phoneNumber} />
            <TextDisplayCard labelText='Role' title={singleUser ? singleUser.role?.name : 'N/A'} />
            <TextDisplayCard
              labelText='Date Of Birth'
              title={moment(singleUser?.dob).format('MMMM Do, YYYY')}
            />
            <TextDisplayCard labelText='Gender' title={singleUser?.gender} />
            <TextDisplayCard labelText='Marital Status' title={singleUser?.maritalStatus} />
            <TextDisplayCard labelText='Nationality' title={singleUser?.nationalId} />
            <TextDisplayCard
              labelText='Driving License'
              title={singleUser?.drivingLicense?.isActive ? 'Active' : 'Inactive'}
            />
            <TextDisplayCard
              labelText='Valid until'
              title={new Date(singleUser?.drivingLicense?.validUntil).toDateString()}
            />
            <TextDisplayCard
              labelText='Driving Categories'
              title={singleUser?.drivingLicense?.drivingCategories?.join(', ')}
            />
          </ItemsGrid>
        </Flex>
      </Animation>
    )
  }

  const Contract = () => {
    return (
      <Animation>
        <Flex gap='1rem' column>
          {singleUser?.contracts?.map((item, idx) => {
            return (
              <ItemsGrid columns={4} key={idx}>
                <TextDisplayCard
                  labelText='Contract Start'
                  title={new Date(item.startDate).toDateString()}
                />
                <TextDisplayCard
                  labelText='Contract Duration'
                  title={new Date(item.endDate).toDateString()}
                />
                <TextDisplayCard labelText='Amount' title={item.value} />
              </ItemsGrid>
            )
          })}
        </Flex>
      </Animation>
    )
  }

  const Machineries = () => {
    return (
      <Animation>
        {singleUser?.machineModels?.length > 0 ? (
          singleUser.machineModels.map((item) => {
            return (
              <Flex width='100%' gap='1rem' key={item.id}>
                <TextDisplayCard width='15rem' title={item.name} labelText='Machine name' />
                <TextDisplayCard title={item.priority} labelText='Priority' />

                <TextDisplayCard title={item.rating} labelText='Performance' />
              </Flex>
            )
          })
        ) : (
          <P1>No Machine models to display</P1>
        )}
      </Animation>
    )
  }

  const Capabilities = () => {
    return (
      <Animation>
        <Flex column gap='1rem'>
          {singleUser?.operations?.length > 0 ? (
            singleUser?.operations?.map((item) => {
              return (
                <Flex width='100%' gap='1rem' key={item.id} column>
                  <TextDisplayCard title={item.name} width='15rem' labelText='Capability title' />
                  {item.types.map((item) => {
                    return (
                      <Flex width='80%' key={item.id} gap='1rem'>
                        <TextDisplayCard
                          title={item.name}
                          labelText='Capability name'
                          width='10rem'
                        />
                        <TextDisplayCard title={item.priority} labelText='Priority' />
                        <TextDisplayCard title={item.rating} labelText='Performance' />
                      </Flex>
                    )
                  })}
                </Flex>
              )
            })
          ) : (
            <P1>No Capabilities to display</P1>
          )}
        </Flex>
      </Animation>
    )
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between>
          <H1WithBack
            title={singleUser ? singleUser.firstName + ' ' + singleUser.lastName : 'N/A'}
            arrow
          />
          <Button orange onClick={() => navigate('edit')}>
            Edit Employee
          </Button>
        </Flex>
        <Separator />

        <Flex between>
          <TabsContainer>
            <Tabs active={active === 'Basic Info'} onClick={() => setActive('Basic Info')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Contract'} onClick={() => setActive('Contract')}>
              Contract
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => setActive('Machines')}>
              Machines
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>

        <ContentWrapper smaller>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
