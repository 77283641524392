import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {  AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllMachineModelsSingleResponse, IMachineModelPostPatchRequest } from 'types/api/machineModelsApi.interface'
import { IMachineModel, IMachineModelsSlice } from 'types/machineModels.interface'
import { handleErrors, formatParams, convertFuelType } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'

const initialState:IMachineModelsSlice = {
  isLoading: false,
  machineModels: [],
  singleModel: null,
  noMachineModels: false,
  totalCount: null,
  totalPages: null,
}

export const getMachineModels = createAsyncThunk(
  'machineModels/getMachineModels',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IGetAllMachineModelsSingleResponse>> = await baseUrl.get('machine-models', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleMachineModel = createAsyncThunk(
  'machineModels/getSingleMachineModels',
  async (id: number | string, thunkAPI) => {
    try {
      const {data:{machineModel}}:{data:IGetAllMachineModelsSingleResponse} = await baseUrl.get('machine-models/' + id)

      const obj:IMachineModel = {
        category:{
          label:machineModel.category.name,
          value:machineModel.category.id
        },
        operations:machineModel.operations.map((o) => {
          const copyO = { ...o }
          return {
            ...copyO,
            types: copyO.types.map((t) => {
              const copyT = { ...t }
              return {
                ...copyT,
                id: copyT.operationTypeId,
                parameters: copyT.parameters.map((p) => {
                  const copyP = { ...p }
                  return { ...copyP, id: copyP.operationTypeParameterId }
                }),
              }
            }),
          }
        }),
        fuelType:machineModel.fuelType,
        fields:machineModel.fields.map(f => ({ ...f.field, value: f.value, fieldId: f.field.id, dropDownOptions: f.field.dropDownItems.map(i => i.name) })),
        id:machineModel.id,
        make:machineModel.make,
        model:machineModel.model,
      }

      return obj
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addMachineModel = createAsyncThunk(
  'postModel/addModel',
  async (model: IMachineModel, thunkAPI) => {
    try {
      const forSubmit:IMachineModelPostPatchRequest={
        operationTypes:model.operations
        .map((item) => {
          const final = item.types
            .map((i) => {
              return {
                operationTypeId: i.id,
                parameterValues: i.parameters.map((t) => {
                  return { operationTypeParameterId: t.id, value: t.value }
                }),
                fuelCost: i.fuelCost ? i.fuelCost : 0,
                rating: i.rating ? i.rating : 0,
                priority: i.priority ? i.priority : 0,
              }
            })
            .flat()
          return final
        })
        .flat(),
        categoryId: model.category.value,
       fieldValues: model.fields.map(f => ({ fieldId: Number(f.fieldId), value: f.value })),
       fuelType:model.fuelType,
        make:model.make,
        model:model.model,
      }

      const resp = await baseUrl.post('machine-models', forSubmit, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateMachineModel = createAsyncThunk(
  'patchModel/updateModel',
  async (model: IMachineModel, thunkAPI) => {
    try {
      const { id } = model
      const forSubmit:IMachineModelPostPatchRequest={
        operationTypes:model.operations
        .map((item) => {
          const final = item.types
            .map((i) => {
              return {
                operationTypeId: i.id,
                parameterValues: i.parameters.map((t) => {
                  return { operationTypeParameterId: t.id, value: t.value }
                }),
                fuelCost: i.fuelCost ? i.fuelCost : 0,
                rating: i.rating ? i.rating : 0,
                priority: i.priority ? i.priority : 0,
              }
            })
            .flat()
          return final
        })
        .flat(),
        categoryId: model.category.value,
       fieldValues: model.fields.map(f => ({ fieldId: f.fieldId, value: f.value })),
       fuelType:model.fuelType,
        make:model.make,
        model:model.model,
      }

      const resp = await baseUrl.patch('machine-models/' + id, forSubmit, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteMachineModel = createAsyncThunk(
  'machineModels/deleteMachineModel',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('machine-models/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const machineModelsSlice = createSlice({
  name: 'machineModels',
  initialState,
  reducers: {
    clearModel: (state) => {
      state.singleModel = null
    },
    clearModelsList: (state:IMachineModelsSlice) => {
      state.machineModels=[]
      state.totalCount=0
      state.totalPages=0
    },
  },
  extraReducers: {
    [getMachineModels.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMachineModels.fulfilled.type]: (state:IMachineModelsSlice, { payload }:{payload:IPaginatedResponse<IGetAllMachineModelsSingleResponse>}) => {
      state.isLoading = false
      state.machineModels = payload.data.map(m=>({
        id:m.machineModel.id,
        image:m.getCategoryImageUrl,
        category:m.machineModel.category.name,
        make:m.machineModel.make,
        model:m.machineModel.model,
        fuelType:convertFuelType(m.machineModel.fuelType),
        operationsCount:m.machineModel.operations.length ?? 0
      }))
      state.totalCount=payload.totalCount
      state.totalPages=payload.totalPages
      state.noMachineModels = state.machineModels.length < 1
    },
    [getMachineModels.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleMachineModel.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleMachineModel.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleModel = payload
    },
    [getSingleMachineModel.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      console.log(payload)
      handleErrors(payload)
    },
    [addMachineModel.pending.type]: (state) => {
      state.isLoading = true
    },
    [addMachineModel.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.machineModels.length < 5) {
        state.machineModels = [...state.machineModels, payload]
      }
      toast.success('Model added')
    },
    [addMachineModel.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateMachineModel.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateMachineModel.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundIndex = state.machineModels.findIndex((item) => item.id === payload.id)
      state.machineModels[foundIndex] = payload
      toast.success('Model updated')
    },
    [updateMachineModel.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteMachineModel.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteMachineModel.fulfilled.type]: (state: any, { payload }) => {
      state.isLoading = false
      state.machineModels.data = state.machineModels.data.filter(
        (item) => item.machineModel.id !== payload,
      )
      state.noMachineModels = state.machineModels.data.length < 1
      toast.success('Model deleted')
    },
    [deleteMachineModel.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})
export const { clearModel, clearModelsList } = machineModelsSlice.actions
export default machineModelsSlice.reducer
