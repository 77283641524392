import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Flex,
  MainHeadline,
  ProfilePicture,
  theme,
  Table,
  Th,
  Tr,
  PageWrapper,
  PageFooter,
  Separator,
  CursorPointer,
  PageControls,
} from '../../styling/GlobalStyles'
import { InfoCard } from 'components/InfoCard/InfoCard'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '../../components/Pagination/Pagination'
import { GraphCardComponent } from '../../components/Charts/GraphCard'
import { GraphWrapper } from '../../components/Charts/GraphCard.styled'
import Avatar from '../../assets/images/profileAvatar.png'
import { Rating } from './Rating'
import { Status } from '../../components/Status/Status'
import { NavBar } from '../../components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearEmployeesList,
  deleteUser,
  getUsers,
  getUserStats,
} from 'features/users(Employees)/usersSlice'
import { AppDispatch } from 'store'
import { Options } from 'components/Options/Options'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import {
  blueChartColors,
  brownChartColors,
  greenChartColors,
  modifiedStats,
  originStats,
} from './constants'
import { handlePagination, getParams } from 'utils/helpers'
import { Td } from 'components/Td/Td'
import { setConfirmModal } from 'features/global/globalSlice'
import useAuth from 'hooks/useAuth'
import { ModalTypes } from 'types/global.interface'
import { Modal } from 'components/Modal/Modal'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { ImageModalFrame } from './Employees.styled'
import { Filter } from 'components/Filter/Filter'
import { FilterEnum, LinkEnum } from 'components/Filter/SingleFilter'
import useQueryPagination from 'hooks/useQueryPagination'
import { contractTypes } from './employeesData'

export const Employees = () => {
  const { users, noUsers, totalPages, stats, infoShifts } = useSelector((store: any) => store.users)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { user } = useAuth()
  const [params, setParams] = useState<any>(getParams({ location }))
  const [imageModal, setImageModal] = useState({ isOpen: false, image: null })

  useQueryPagination({ fetchFunctions: [getUsers, getUserStats], cleanUpFunctions: [clearEmployeesList], params })


  const statsArr = [
    {
      title: 'Active Workers',
      chartColors: greenChartColors,
      color: theme.greenChart,
      data: originStats('Active Workers', stats),
      modifiedData: modifiedStats('active', stats),
    },
    {
      title: 'Sick Relief Workers',
      chartColors: brownChartColors,
      color: theme.brownChart,
      data: originStats('Sick Relief Workers', stats),
      modifiedData: modifiedStats('sick', stats),
    },
    {
      title: 'Workers On Vacation',
      chartColors: blueChartColors,
      color: theme.blueChart,
      data: originStats('Workers On Vacation', stats),
      modifiedData: modifiedStats('vacation', stats),
    },
    // {
    //   title: 'Low Rating Workers',
    //   chartColors: greenChartColors,
    //   color: theme.greenChart,
    //   data: originStats('lowRating', stats),
    //   modifiedData: modifiedStats('lowRating', stats),
    // },
  ]

  const filters = [
    {
      type: FilterEnum.SEARCH,
      link: LinkEnum.ROLES,
      name: 'Roles',
      state: null,
    },
    {
      type: FilterEnum.SEARCH,
      link: LinkEnum.OPERATION_TYPES,
      name: 'Operation Types',
      state: null,
      companyId: true,
    },
  ]

  const handleDelete = (id: number | string) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteUser(id))
          dispatch(getUserStats())
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: users.length < 2,
        },
        word: 'delete employee',
      }),
    )
  }

  const ImageModal = () => {
    const imageModalRef = useRef(null)

    useEffect(() => {
      const outSideClick = (e) => {
        if (!imageModalRef.current.contains(e.target)) {
          setImageModal({ isOpen: false, image: null })
        }
      }

      document.addEventListener('mousedown', outSideClick)

      return () => {
        document.removeEventListener('mousedown', outSideClick)
      }
    }, [])

    return (
      <Flex column gap='2rem' ref={imageModalRef}>
        <Flex end='true' width='100%'>
          <CursorPointer onClick={() => setImageModal({ isOpen: false, image: null })}>
            <CloseIcon />
          </CursorPointer>
        </Flex>
        <ImageModalFrame>
          <img
            src={imageModal.image || Avatar}
            alt='profile-image'
            onError={({ currentTarget }) => (currentTarget.src = Avatar)}
          />
        </ImageModalFrame>
      </Flex>
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Employees...' setParams={setParams} limit={30} />
      <PageWrapper noFooter={totalPages < 2}>
        <PageControls>
          <MainHeadline>Employees</MainHeadline>
          <Flex gap='1rem'>
            <Button orange onClick={() => navigate('/employees/add')}>
              Add Employee
            </Button>
            <Filter options={filters} setPageParams={setParams} />
          </Flex>
        </PageControls>
        <Separator />

        <GraphWrapper>
          {statsArr.map((item) => {
            return (
              <GraphCardComponent
                key={item.title}
                color={item.color}
                data={item.data}
                chartColors={item.chartColors}
                title={item.title}
                modifiedData={item.modifiedData}
              />
            )
          })}

          <InfoCard title='Workers By Shifts' data={infoShifts} />
        </GraphWrapper>

        {noUsers ? (
          <NoItems title='Employees' />
        ) : (
          <Table>
            <Th>
              <Td w='20%' noTooltip text='Full Name' />
              <Td w='15%' noTooltip text='Phone' />
              <Td w='20%' noTooltip text='Role' />
              <Td w='12%' noTooltip text='Net Salary' />
              <Td w='15%' noTooltip text='Rating' />
              <Td w='15%' noTooltip text='Status' />
              <Td w='20%' noTooltip text='Reward Points' />
            </Th>

            {users &&
              users.map((item) => {
                return (
                  <Tr key={item.id}>
                    <Td w='6%' noTooltip>
                      <Flex center>
                        <ProfilePicture mr='20'>
                          <img
                            src={item.getImagePresignedUrl || Avatar}
                            alt=''
                            onClick={() =>
                              setImageModal({ isOpen: true, image: item.getImagePresignedUrl })
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = Avatar
                            }}
                          />
                        </ProfilePicture>
                      </Flex>
                    </Td>
                    <Td w='14%' text={item.firstName.concat(' ' + item.lastName)} />
                    <Td w='15%' text={item.contact.phoneNumber} />
                    <Td w='20%' text={item?.role?.name} />
                    <Td
                      w='12%'
                      text={
                        item.contract?.value
                          ? '$' + item.contract?.value + ' ' + contractTypes.find(c => c.value == item.contract?.type).label
                          : 'No active contracts'
                      }
                    />
                    <Td w='15%' noTooltip>
                      <Rating rating={item.overallRating?.toFixed(1)} />
                    </Td>
                    <Td w='15%' noTooltip>
                      <Status status={item.state} />
                    </Td>
                    <Td w='15%' noTooltip text={item?.rewardPoints || 'No Reward Points'} />
                    <Td noTooltip>
                      <Options
                        item={item}
                        invisible={(user.id == item.id)}
                        title='Employee'
                        handleRemove={() => handleDelete(item.id)}
                      />
                    </Td>
                  </Tr>
                )
              })}
          </Table>
        )}
        {imageModal.isOpen && (
          <Modal black>
            <ImageModal />
          </Modal>
        )}
      </PageWrapper>
      {Math.ceil(totalPages) > 2 && <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 30)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>}
    </>
  )
}
