import styled from 'styled-components'
import React from 'react'
import { Hamburger as Icon } from 'assets/icons/sidebarHamburger'
import { useDispatch } from 'react-redux'
import { toggleSideBar } from 'features/global/globalSlice'
import { AppDispatch } from 'store'

export const SharedLayoutWrapper = styled.main`
  width: 100vw;
  background-color: #ffffff;
`
interface DashboardContainerProps {
  sidebar?: boolean
}
export const DashboardContainer = styled.div<DashboardContainerProps>`
  position: fixed;
  top: 5rem;
  left: ${(props) => (props.sidebar ? '16rem' : '1rem')};
  bottom: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
`

interface HamburgerStyledProps {
  sidebar?: boolean
}

const HamburgerStyled = styled.div<HamburgerStyledProps>`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 2rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 1.8rem rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 4rem;
`

export const HamburgerComponent = ({ sidebar }: { sidebar: boolean }) => {
  const dispatch = useDispatch<AppDispatch>()

  return (
    <HamburgerStyled sidebar={sidebar} onClick={() => dispatch(toggleSideBar())}>
      {Icon}
    </HamburgerStyled>
  )
}

