/* eslint-disable*/

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import { SharedLayoutWrapper, DashboardContainer, HamburgerComponent } from './SharedLayout.styled'
import useAuth from 'hooks/useAuth'
import { setLoadingAuth } from 'features/global/globalSlice'
import { RootState } from 'store'
import { permissionsAndLinks } from 'utils/helpers'

export const SharedLayout = () => {
  const { isAdmin } = useAuth()
  const { isSidebarOpen } = useSelector((store: RootState) => store.globals)
  const { user } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname == '/') {
      dispatch(setLoadingAuth(true))
      if (isAdmin) {
        ; +navigate('/operations')
      } else {
        const page = Object.keys(user?.role?.permissions || {}).find(o => user?.role?.permissions[o])
          ; +navigate('/' + page ? permissionsAndLinks[page] : 'employees')
      }
    }
  }, [])

  return (
    <SharedLayoutWrapper>
      <HamburgerComponent sidebar={isSidebarOpen} />
      <Sidebar />
      <DashboardContainer sidebar={isSidebarOpen}>
        <Outlet />
      </DashboardContainer>
    </SharedLayoutWrapper>
  )
}
