import React, { useState, useEffect, useRef } from 'react'
import { Flex, CursorPointer, Separator, Button, H1 } from '../../styling/GlobalStyles'
import { Input } from '../Input/Input'
import { AddCircle } from '../../assets/icons/AddCircle'
import { CloseIcon } from '../../assets/icons/CloseIcon'
import RemoveCircle from '../../assets/icons/RemoveCircle'
import { v4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { addField, editField } from '../../features/fields/fieldsSlice'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { getUnits } from 'features/units/unitsSlice'
import { ISelectValue } from 'constants/globalTypes'
import { IDropDownItem, IField } from 'types/fields.interface'
import { toast } from 'react-toastify'
import { addInvField, editInvField } from 'features/inventory/invSettingsFieldsSlice'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { addWorkerComponent, editWorkerComponent } from 'features/workerComponents/workerComponentsSlice'
import { workerComponentTypes } from 'pages/WorkerComponents/workerComponentsData'

export const FieldsModal = ({ close, field, edit, inventory, workerComponents }: IFieldsModal) => {
  const [formState, setFormState] = useState<IField>(
    field ? structuredClone({ ...field, unitId: field.unit?.id }) : { name: '' },
  )
  const [subFields, setSubFields] = useState(
    field && field.dropDownItems
      ? structuredClone(field).dropDownItems.map((item: IDropDownItem) => ({
        ...item,
        id: v4(),
      }))
      : [],
  )
  const modalRef = useRef(null)
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const units = useSelector((state: RootState) => state.units.units)
  const [localUnits, setLocalUnits] = useState<ISelectValue[]>([])
  const [unitSelectVisible, setUnitSelectVisible] = useState(formState?.unit?.id ? true : false)
  const [drop, setDrop] = useState(field?.dropDownItems?.length > 0)

  useFocusFirstInput()

  useEffect(() => {
    dispatch(getUnits())
  }, [])

  useEffect(() => {
    if (Array.isArray(units)) {
      setLocalUnits(
        units.map((unit) => ({
          label: unit.name,
          value: unit.id,
        })),
      )
    }
  }, [units])

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        close(false)
      }
    }
    const outSideClick = (event) => {
      if (modalRef.current) {
        const rect = modalRef.current.getBoundingClientRect();
        const { clientX: x, clientY: y } = event;

        // Check if the click is outside the bounds
        if (
          x < rect.left || x > rect.right ||
          y < rect.top || y > rect.bottom
        ) {
          close(false)
        }
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('mousedown', outSideClick)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('mousedown', outSideClick)
    }
  }, [])

  const listReact = [
    { label: 'Text', value: 1 },
    { label: 'Number', value: 2 },
    { label: 'Dropdown', value: 3 },
  ]
  const list2React = [
    { label: 'Text', value: 1 },
    { label: 'Number', value: 2 },
  ]

  const listTypes = { Text: 1, Dropdown: 3, Number: 2 }

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => {
      return { ...prev, name: e.target.value }
    })
  }

  const handleType = (value: ISelectValue) => {
    if (value) {
      if (value.label == 'Text' || value.label == 'Dropdown') {
        setUnitSelectVisible(false)
      } else if (value.label) {
        setUnitSelectVisible(true)
      }

      setFormState((prev) => {
        const copy = { ...prev }
        if (value.label == 'Text' || value.label == 'Dropdown') {
          return { ...copy, dataType: listTypes[value.label], unitId: null }
        } else {
          return { ...copy, dataType: listTypes[value.label] }
        }
      })
      if (value.label == 'Dropdown') {
        setDrop(true)
        if (subFields.length < 1) {
          setSubFields([
            { id: v4(), name: '', dataType: 1, value: null },
            { id: v4(), name: '', dataType: 1, value: null },
          ])
        }
      } else {
        setDrop(false)
        setSubFields([])
      }
    } else {
      setDrop(false)
      setSubFields([])
    }
  }

  const handleUnit = (value: ISelectValue) => {
    setFormState((prev) => {
      return { ...prev, unitId: value.value }
    })
  }

  const handleWorkerComponentType = (value: ISelectValue) => {
    setFormState((prev) => {
      return { ...prev, componentType: value.value }
    })
  }
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubFields((prev: IDropDownItem[]) => {
      const newAr: IDropDownItem[] = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id == e.target.id)
      if (objIndex > -1) {
        newAr[objIndex].name = e.target.value
      } else {
        newAr.push({
          id: e.target.id,
          name: e.target.value,
          dataType: 1,
          value: null,
        })
      }
      return newAr
    })
  }

  const selectChange = (value: ISelectValue, name: string) => {
    setSubFields((prev: IDropDownItem[]) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id == name)
      if (objIndex > -1) {
        newAr[objIndex].dataType = (value.value as 1 | 2)
      } else {
        newAr.push({
          id: name,
          name: '',
          dataType: listTypes[value.label],
          value: null,
        })
      }
      return newAr
    })
  }

  const renderFields = () => {
    return (
      subFields &&
      subFields.map((item: IDropDownItem) => {
        return (
          <Flex key={item.id} center width='100%' mb='20' gap='1em' position='relative'>
            <Input
              id={item.id}
              value={item.name}
              nomarg
              type='text'
              placeholder='Enter title'
              invalid={item?.name?.length < 1 ? 'true' : ''}
              onChange={inputChange}
            />
            <HonestSelectInput
              name={String(item.id)}
              onChange={(value, name) => selectChange(value as ISelectValue, name)}
              nomarg
              defaultValue={getSelectedType(item.dataType)}
              options={list2React}
            />
            {subFields.length > 2 && (
              <CursorPointer
                onClick={() => {
                  setSubFields((prev) => {
                    prev = prev.filter((it: IDropDownItem) => it.id !== item.id)
                    return prev
                  })
                }}
              >
                <RemoveCircle />
              </CursorPointer>
            )}
          </Flex>
        )
      })
    )
  }

  const getSelectedType = (type: any, units?: any) => {
    if (type) {
      const option = units
        ? localUnits.find((item) => item.value === type)
        : listReact.find((item) => item.value === type)

      return option ? option.value : null
    }
    return null
  }

  const handleFieldSubmit = async () => {
    const objForDispatch = {
      ...formState,
      dropDownItems: subFields.map((subField: IDropDownItem) => {
        delete subField.id
        return subField
      }),
    }
    delete objForDispatch.unit
    const paramObj = edit ? { ...objForDispatch, id: Number(id) } : objForDispatch

    if (!formState.name || !formState.dataType || (formState.dataType == 2 && !formState.unitId)) {
      toast.warn('All inputs are required')
    } else {
      let resp: any
      if (inventory) {
        resp = await dispatch(edit ? editInvField(paramObj) : addInvField(paramObj))
      } else if (workerComponents) {
        resp = await dispatch(edit ? editWorkerComponent(paramObj) : addWorkerComponent(paramObj))
      }
      else {
        resp = await dispatch(edit ? editField(paramObj) : addField(paramObj))
      }
      if (resp.meta.requestStatus === 'fulfilled') {
        close()
      }
    }
  }

  return (
    <div  >
      <Flex column ref={modalRef}>
        <Flex between width='100%'>
          <H1>{edit ? 'Edit ' + field?.name : 'Add field'}</H1>
          <CursorPointer onClick={() => close(false)}>
            <CloseIcon />
          </CursorPointer>
        </Flex>
        <Separator />

        <Flex between column>
          <Flex center width='93%' gap='1em' mb='20'>
            <Input
              id='first'
              placeholder='Enter title'
              nomarg
              type='text'
              value={formState?.name}
              onChange={handleName}
              labelText='Field name'
              invalid={formState?.name?.length < 1 ? 'true' : ''}
            />
            <HonestSelectInput
              name='first'
              onChange={(value) => handleType(value as ISelectValue)}
              labelText='Data type'
              nomarg
              defaultValue={formState ? getSelectedType(formState.dataType) : null}
              options={listReact}
              invalid={formState?.dataType ? false : true}
            />
          </Flex>
          <Flex column maxHeight='320px' overflow>
            {renderFields()}
          </Flex>
          {drop && (
            <Flex start='true' width='100%'>
              <CursorPointer
                onClick={() => {
                  setSubFields((prev: IDropDownItem[]) => {
                    prev = [...prev, { id: v4(), dataType: 1, value: null, name: null }]
                    return prev
                  })
                }}
              >
                <AddCircle />
              </CursorPointer>
            </Flex>
          )}
          <Flex width='100%' between >
            {unitSelectVisible && localUnits.length > 1 && (
              <HonestSelectInput
                onChange={(value: ISelectValue) => handleUnit(value)}
                options={localUnits}
                labelText='Measurement unit'
                defaultValue={formState.unit?.id}
                width='15rem'
              />
            )}
            {
              workerComponents &&
              <HonestSelectInput
                onChange={(value: ISelectValue) => handleWorkerComponentType(value)}
                options={workerComponentTypes}
                labelText='Component Type'
                defaultValue={formState.componentType}
                width='15rem'
              />

            }
          </Flex>


        </Flex>
        <Flex mt='5' end='true' width='100%'>
          <Button
            disabled={
              formState?.name?.length < 1 ||
              !formState?.dataType ||
              (formState?.dataType == 2 && !formState.unit?.id && !formState.unitId) ||
              subFields.some((field) => !field.name || !field.dataType)
            }
            orange
            onClick={handleFieldSubmit}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </div>
  )
}



interface IFieldsModal {
  close: (b?: boolean) => void
  field?: any // TODO:  ispitati
  edit?: boolean
  inventory?: boolean
  workerComponents?: boolean
}