import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IGlobalViewAreaPatchRequest, IGlobalViewAreaPostRequest, IGlobalViewAreasResponse, IGlobalViewMachinesReponse, IGlobalViewUsersResponse } from 'types/api/globalViewApi.interface'
import { IGlobalViewSlice } from 'types/global-view.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors } from 'utils/helpers'

const initialState: IGlobalViewSlice = {
  users: [],
  machines: [],
  isLoading: false,
  areas: [],
  totalPages: 0,
  totalCount: 0,
}



export const getGlobalViewUsers = createAsyncThunk(
  'globalView/users',
  async ({status, initialLoading}: {status: 'Free' | 'Busy', initialLoading:boolean}, thunkAPI) => {
    try {
      const resp:AxiosResponse<IGlobalViewUsersResponse> = await baseUrl.get('global-view-locations/users',{ params:{status} })
      return {data:resp.data,initialLoading}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getGlobalViewMachines = createAsyncThunk(
  'globalView/machines',
  async ({status, initialLoading}: {status: 1 | 2, initialLoading:boolean}, thunkAPI) => {
    try {
      const resp:AxiosResponse<IGlobalViewMachinesReponse> = await baseUrl.get('global-view-locations/machines', { params:{status} })
      return {data:resp.data, initialLoading}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getGlobalViewAreas = createAsyncThunk(
  'globalView/areas',
  async (params: any, thunkAPI) => {
    try {
      const resp:AxiosResponse<IGlobalViewAreasResponse> = await baseUrl.get('geofence-area', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addGlobalViewArea = createAsyncThunk(
  'globalView/addArea',
  async (area: IGlobalViewAreaPostRequest, thunkAPI) => {
    try {
      const resp = await baseUrl.post('geofence-area', area)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteGlobalViewArea = createAsyncThunk(
  'globalView/deleteArea',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('geofence-area/' + id)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateGlobalViewArea = createAsyncThunk(
  'globalView/editArea',
  async (area: IGlobalViewAreaPatchRequest, thunkAPI) => {
    try {
      const resp = await baseUrl.patch('geofence-area/' + area.id, area)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const globalViewSlice = createSlice({
  name: 'globalViewSlice',
  initialState,
  reducers: {
    cleanGlobalView: (state) => {
      state.users = []
      state.areas = []
      state.machines = []
    },
    editUserPosition: (state, {payload}:{payload:{id:string, position:{latitude:number, longitude:number}}}) => {
      const userIndex = state.users.findIndex(u=>u.id == Number(payload.id.split('-')[1]))
      state.users[userIndex].position=payload.position
    },
  },
  extraReducers: {
    [getGlobalViewUsers.pending.type]: (state, payload) => {
      if(payload.meta.arg.initialLoading){
        state.isLoading = true
      }
    },
    [getGlobalViewUsers.fulfilled.type]: (state: IGlobalViewSlice, { payload }) => {
      state.isLoading = false
      state.users = payload.data.users
    },
    [getGlobalViewUsers.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getGlobalViewMachines.pending.type]: (state, payload) => {
      if(payload.meta.arg.initialLoading){
        state.isLoading = true
      }
    },
    [getGlobalViewMachines.fulfilled.type]: (state: IGlobalViewSlice, { payload }) => {
      state.isLoading = false
      state.machines = payload.data
    },
    [getGlobalViewMachines.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addGlobalViewArea.pending.type]: (state) => {
      state.isLoading = true
    },
    [addGlobalViewArea.fulfilled.type]: (state: IGlobalViewSlice, { payload }) => {
      state.isLoading = false
      state.areas = [...state.areas, payload]
      toast.success('Area successsfully added.')
    },
    [addGlobalViewArea.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateGlobalViewArea.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateGlobalViewArea.fulfilled.type]: (state: IGlobalViewSlice, { payload }) => {
      state.isLoading = false
      const index = state.areas.findIndex((a) => a.id == payload.id)
      state.areas[index] = payload
      toast.success('Area successsfully edited.')
    },
    [updateGlobalViewArea.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteGlobalViewArea.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteGlobalViewArea.fulfilled.type]: (state: IGlobalViewSlice, { payload }) => {
      state.isLoading = false
      state.areas = state.areas.filter((a) => a.id !== payload)
      toast.success('Area successsfully deleted.')
    },
    [deleteGlobalViewArea.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getGlobalViewAreas.pending.type]: (state) => {
      state.isLoading = true
    },
    [getGlobalViewAreas.fulfilled.type]: (state: any, { payload }) => {
      state.isLoading = false
      state.areas = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
    },
    [getGlobalViewAreas.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { cleanGlobalView, editUserPosition } = globalViewSlice.actions
export default globalViewSlice.reducer
