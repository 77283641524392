import React, { useState } from 'react'
import {
  Button,
  ButtonsContainer,
  ItemsPickContainer,
  Flex,
  H2,
  SelectedItemsContainer,
  TabFooter,
  Title,
  Animation,
} from '../../styling/GlobalStyles'
import { Input } from '../../components/Input/Input'
import { CancelBtn } from 'components/Button/CancelBtn'
import { AddOrangeBtn } from 'components/Button/AddOrangeBtn'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { toast } from 'react-toastify'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { IContract, IEmployeesTab } from 'types/users.interface'
import { v4 } from 'uuid'
import { checkInvalid, convertToDate } from 'utils/helpers'
import { standardFieldValidation, validateDate } from 'utils/validationUtils'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { ContractInputWrapper } from './Employees.styled'
import { contractTypes, employeeContractInputs } from './employeesData'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { ISelectValue } from 'constants/globalTypes'

export const EmployeesContractsTab = ({ setActive, state, setState }: IEmployeesTab) => {
  const [selectedContract, setSelectedContract] = useState<IContract>(null)
  const isEditing = location.pathname.includes('edit')
  const [invalidFields, setInvalidFields] = useState<string[]>([])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement

    standardFieldValidation(e, setInvalidFields)
    setSelectedContract((prev) => {
      const copy = { ...prev }
      copy[name] = Number(value)
      return copy
    })
  }

  const checkValid = () => {
    if (invalidFields.length > 0) {
      console.log(invalidFields)
      return 'All contract fields must be valid'
    }

    if (!selectedContract?.contractIndefinite && new Date(selectedContract?.startDate).getTime() > new Date(selectedContract?.endDate).getTime()) {
      return 'Contract start date cannot be after or the same as the end date'
    }
    // if(state.contracts.some(c=>c.endDate.getTime() > selectedContract.startDate.getTime() || c.startDate.getTime() < selectedContract.endDate()){
    //   resp = 'Contract with given time period already exists'
    // }  For future maybe
  }

  const handleDate = (e: Date, name: string) => {
    validateDate(name, setInvalidFields)
    setSelectedContract((prev) => {
      const copy = { ...prev }
      copy[name] = e
      if (name === 'startDate' && !copy.contractIndefinite && !copy.endDate) {
        const dt = new Date(e)
        copy.endDate = new Date(dt.setMonth(dt.getMonth() + 6))
        setInvalidFields((prevF) => {
          let copyF = [...prevF]
          copyF = copyF.filter((f: string) => f !== 'endDate')
          return copyF
        })
      }
      return copy
    })
  }

  const addContract = () => {
    if (selectedContract) {
      const msg = checkValid()
      if (msg) {
        toast.error(msg)
        return
      }
      addSelectedToState()
      return
    }
    const contract = {
      startDate: new Date(),
      endDate: null,
      signed: false,
      contractIndefinite: false,
      id: v4(),
      type: 1,
      value: null
    }
    setState((prev) => {
      const copy = { ...prev }
      copy.contracts.push(contract)
      return copy
    })
    setSelectedContract(contract)
    setInvalidFields(employeeContractInputs)
  }

  const handleCheck = () => {
    setSelectedContract((prev) => {
      const copy = { ...prev }
      copy.contractIndefinite = !copy.contractIndefinite
      if (copy.contractIndefinite) {
        copy.endDate = null
        setInvalidFields((prevF) => {
          let fieldsCopy = [...prevF]
          fieldsCopy = fieldsCopy.filter((f: string) => f !== 'endDate')
          return fieldsCopy
        })
      }
      return copy
    })
  }

  const addSelectedToState = () => {
    setState((prev) => {
      const copy = { ...prev }
      const index = state.contracts.findIndex((c: IContract) => c.id === selectedContract.id)
      state.contracts[index] = selectedContract
      return copy
    })
  }

  const handleSaveContract = (clearFields?: boolean) => {
    const msg = checkValid()
    if (msg) {
      toast.error(msg)
      return
    }
    if (selectedContract) {
      addSelectedToState()
      if (clearFields) {
        setSelectedContract(null)
      }
    }
  }

  const handleNext = () => {
    if (selectedContract) {
      const msg = checkValid()
      if (msg) {
        toast.error(msg)
        return
      }
      addSelectedToState()
      setActive('Machines')
    } else {
      setActive('Machines')
    }
  }

  const handleSelectContract = (item: IContract) => {
    handleSaveContract(false)
    setSelectedContract(item)
  }

  const handleRemove = () => {
    setInvalidFields([])
    setSelectedContract(null)
  }

  const handleSelect = (v: ISelectValue) => {
    setSelectedContract(prev => {
      const copy = structuredClone(prev)
      copy.type = v.value
      return copy
    })
  }

  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Flex between mb='15'>
            <Title pad='0 20px'>Contracts</Title>
            <AddOrangeBtn onClick={addContract} />
          </Flex>
          {state?.contracts.map((item: IContract, i: number) => (
            <SelectedItem
              onClick={() => handleSelectContract({ ...item, name: i + 1 })}
              onRemove={handleRemove}
              setState={setState}
              key={item.id}
              data={{ ...item, name: 'Contract ' + convertToDate(item.startDate) }}
              type='contracts'
            />
          ))}
        </SelectedItemsContainer>
        {selectedContract &&
          state.contracts.some((c: IContract) => c.id === selectedContract.id) ? (
          <ContractInputWrapper>
            <H2>Contract {convertToDate(selectedContract.startDate)}</H2>

            <DatePickerInput
              onChange={handleDate}
              name='startDate'
              label='Contract Start'
              maxDate={selectedContract.endDate}
              date={
                !isEditing || (isEditing && !selectedContract?.startDate)
                  ? selectedContract?.startDate
                  : new Date(selectedContract?.startDate.toString())
              }
            />

            <CheckBox
              state={selectedContract.contractIndefinite}
              onClick={handleCheck}
              title='Contract indefinite'
            />

            {selectedContract?.contractIndefinite ? null : (
              <DatePickerInput
                onChange={handleDate}
                name='endDate'
                label='Contract Duration'
                date={
                  selectedContract?.endDate ? new Date(selectedContract?.endDate?.toString()) : null
                }
              />
            )}
            <HonestSelectInput
              options={contractTypes}
              onChange={handleSelect}
              defaultValue={selectedContract.type}
              name='type'
              labelText='Contract type'
            />
            <Input
              type='number'
              labelText='Amount'
              name='value'
              value={selectedContract?.value}
              nomarg
              onChange={handleChange}
              placeholder='Enter amount'
              invalid={checkInvalid(invalidFields, 'value')}
            />

            <Button orange onClick={() => handleSaveContract(true)}>
              Save
            </Button>
          </ContractInputWrapper>
        ) : (
          <Flex width='100%' center column gap='1rem' minHeight='100%' justcenter>
            <H2>No Contract selected</H2>
            <Button onClick={addContract} orange>
              Add new
            </Button>
          </Flex>
        )}
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleNext}>
            Next
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}
