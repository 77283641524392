
import React, { useState, useRef } from 'react'
import { FoldMapIcon } from 'assets/icons/FoldMapIcon'
import { TruckIcon } from 'assets/icons/TruckIcon'
import { UserIcon } from 'assets/icons/UserIcon'
import { NavBar } from 'components/NavBar/NavBar'
import { Flex, PageWrapper, MapHTMLComponentsOverlay } from 'styling/GlobalStyles'
import {
  GlobalViewControls,
  GlobalViewMobileMenu,
  GlobalViewMobileOptions,
  GlobalViewOptionsContainer,
  GlobalViewSelect,
  GlobalViewSelectOption,
  GlobalViewToggle,
} from './GlobalView.styled'
import { SettingsIcon } from 'assets/icons/SettingsIcons'
import Chevron from 'assets/icons/Chevron'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { Wrapper } from '@googlemaps/react-wrapper'
import { LatLongContainer, ZonesOrangeCircle, ZonesSideMenu } from 'pages/Zones/Zones.styled'
import { ICreating, ILatLong } from 'types/zones.interface'
import { validationWithCoordinates } from 'utils/validationUtils'
import { checkInvalid, sortByCounterClockwiseOrderForPolygon } from 'utils/helpers'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { Input } from 'components/Input/Input'
import { WorldIcon } from 'assets/icons/WorldIcon'
import { toast } from 'react-toastify'
import { PlusIcon } from 'assets/icons/PlusIcon'
import { DeleteIcon } from 'assets/icons/DeleteIcon'
import { RestartIcon } from 'assets/icons/RestartIcon'
import Check from 'assets/icons/Check'
import { computeArea, LatLng } from 'spherical-geometry-js'
import { setConfirmModal } from 'features/global/globalSlice'
import { AreaInputs } from './AreaInputs'
import { GlobalViewMap } from './GlobalViewMap'
import {
  addGlobalViewArea,
  deleteGlobalViewArea,
  updateGlobalViewArea,
  getGlobalViewAreas,
  getGlobalViewUsers,
  getGlobalViewMachines,
} from 'features/globalView/globalViewSlice'
import { Hamburger } from 'assets/icons/sidebarHamburger'

export const GlobalView = () => {
  const [map, setMap] = useState<any>()
  const [mapType, setMapType] = useState('satellite')
  const [select, setSelect] = useState({ open: false, selected: 'ALL' })
  const [creating, setCreating] = useState<
    Pick<ICreating, 'areaName' | 'areaSize' | 'path' | 'currentlyCreating' | 'updating' | 'id'>
  >({ path: [], areaName: '', areaSize: null })
  const [currentlyAdding, setCurrentlyAdding] = useState<ILatLong>({ lat: null, lng: null })
  const [menuOpen, setMenuOpen] = useState(false)
  const [mobileOptions, setMobileOptions] = useState(false)
  const [invalidFieldsCoords, setInvalidFieldsCoords] = useState<string[]>([]) // <--- VALID
  const currentlyCreatingPolygon = useRef(null)
  const currentlyAddingMarkers = useRef([])
  const [toggleAreaControls, setToggleAreaControls] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const loadedPolygons = useRef([])
  const usersCluster = useRef(null)
  const machinesCluster = useRef(null)

  const [filters, setFilters] = useState({
    users: true,
    machines: false,
    areas: false,
  })

  const handleToggle = async (property: string) => {
    setFilters((prev) => {
      const copy = { ...prev }
      copy[property] = !copy[property]
      return copy
    })
    const usersStatus = select.selected !== 'ALL' ? (select.selected == 'IDLE' ? 'Free' : 'Busy') : null
    const machinesStatus = select.selected !== 'ALL' ? (select.selected == 'IDLE' ? 2 : 1) : null
    switch (property) {
      case 'areas':
        dispatch(getGlobalViewAreas(null))
        loadedPolygons.current.forEach((p) => {
          p.polygon.setMap(null)
          p.label.setMap(null)
        })
        loadedPolygons.current = []
        currentlyAddingMarkers.current.forEach(m => m.setMap(null))
        currentlyAddingMarkers.current = []
        setCreating({ path: [], areaSize: null, areaName: '' })
        setMenuOpen(false)
        break
      case 'users':
        dispatch(getGlobalViewUsers({ status: usersStatus, initialLoading: true }))
        usersCluster.current?.setMap(null)
        usersCluster.current = null
        break;
      case 'machines':
        const resp = await dispatch(getGlobalViewMachines({ status: machinesStatus, initialLoading: true }))
        const dt = (resp.payload as any).data.filter(d => d.vehicle) // TOOD Resiti any
        map.setCenter({ lng: dt[0].vehicle.longitude, lat: dt[0].vehicle.latitude })
        map.setZoom(10)
        machinesCluster.current?.setMap(null)
        machinesCluster.current = null
        break;
    }
  }


  const handleSelect = (e, option: string) => {
    e.stopPropagation()
    setSelect({ open: false, selected: option })
  }

  const handleMapType = () => {
    if (mapType == 'satellite') {
      map.setMapTypeId('terrain')
      setMapType('terrain')
    } else {
      map.setMapTypeId('satellite')
      setMapType('satellite')
    }
  }

  const handleMenuOpen = () => {
    if (creating.currentlyCreating) {
      toast.warn('Area already created, please enter area info.')
    } else {
      map.addListener('click', (e) => {
        setCreating((prev: ICreating) => {
          const copy = structuredClone(prev)
          currentlyAddingMarkers.current.push(
            new google.maps.Marker({
              position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
              map,
            }),
          )
          copy.path.push({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          if (copy.path.length > 2) {
            currentlyCreatingPolygon.current?.setMap(null)
            currentlyCreatingPolygon.current = new google.maps.Polygon({
              paths: copy.path,
              fillColor: '#fff',
              map,
              strokeWeight: 0.5,
            })
            copy.areaSize = Number(
              computeArea(copy.path.map((i: ILatLong) => new LatLng(i.lat, i.lng, false))).toFixed(4),
            )
          }
          return copy
        })
        setCurrentlyAdding({ lat: null, lng: null })
      })


      setMenuOpen(true)
      setCreating((prev) => {
        const copy = structuredClone(prev)
        copy.currentlyCreating = true
        return copy
      })
    }
  }

  const handleEnterCoords = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement
    let parsedValue = parseFloat(value)
    validationWithCoordinates(e, setInvalidFieldsCoords)
    if (isNaN(parsedValue)) {
      parsedValue = undefined
    }
    setCurrentlyAdding((prev) => {
      const copy = { ...prev }
      name == 'lat' ? (copy.lat = parsedValue) : (copy.lng = parsedValue)
      return copy
    })
  }

  const handleDone = async () => {
    if (!creating.areaName || creating.areaName.length < 2) {
      toast.warn('Area name must be valid.')
      return
    }
    if (creating.path.length < 3) {
      toast.warn('Area must have at least 3 points')
      return
    } else {
      google.maps.event.clearListeners(map, 'click')
      let resp: any
      if (creating.updating) {
        resp = await dispatch(updateGlobalViewArea({
          areaName: creating.areaName, areaSize: creating.areaSize, id: Number(creating.id),
          path: [...sortByCounterClockwiseOrderForPolygon(creating.path), sortByCounterClockwiseOrderForPolygon(creating.path)[0]]
        }))
      } else {
        resp = await dispatch(addGlobalViewArea({
          areaName: creating.areaName, areaSize: creating.areaSize,
          path: [...sortByCounterClockwiseOrderForPolygon(creating.path), sortByCounterClockwiseOrderForPolygon(creating.path)[0]]
        }))
      }
      if (resp.meta.requestStatus === 'fulfilled') {
        setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
        setCurrentlyAdding({ lat: null, lng: null })
        setMenuOpen(false)
        currentlyCreatingPolygon.current?.setMap(null)
      }
    }
    currentlyAddingMarkers.current.forEach((m) => m.setMap(null))
    google.maps.event.clearListeners(map, 'click')
    !filters.areas && handleToggle('areas')
  }

  const handleAddCoords = () => {
    if (
      currentlyAdding.lat &&
      currentlyAdding.lng &&
      typeof currentlyAdding.lat == 'number' &&
      typeof currentlyAdding.lng == 'number' &&
      invalidFieldsCoords.length < 1
    ) {
      setCreating((prev: ICreating) => {
        const copy = structuredClone(prev)
        currentlyAddingMarkers.current.push(
          new google.maps.Marker({
            position: { lat: currentlyAdding.lat, lng: currentlyAdding.lng },
            map,
          }),
        )
        copy.path.push(currentlyAdding)
        if (copy.path.length > 2) {
          currentlyCreatingPolygon.current = new google.maps.Polygon({
            paths: copy.path,
            fillColor: '#fff',
            map,
            strokeWeight: 0.5,
          })
          copy.areaSize = Number(
            computeArea(copy.path.map((i: ILatLong) => new LatLng(i.lat, i.lng, false))).toFixed(4),
          )
        }
        return copy
      })
      setCurrentlyAdding({ lat: null, lng: null })
    } else {
      toast.error('Please enter valid coordinates')
    }
    map.setCenter({ lng: currentlyAdding.lng, lat: currentlyAdding.lat })
    map.setZoom(10)
  }

  const handleDeleteArea = async () => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteGlobalViewArea(Number(creating.id)))
          dispatch(setConfirmModal({ isOpen: false }))
        },
        word: 'delete area',
      }),
    )
    currentlyAddingMarkers.current.forEach(m => m.setMap(null))
    currentlyAddingMarkers.current = []
    setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
    setCurrentlyAdding({ lat: null, lng: null })
    setMenuOpen(false)
    google.maps.event.clearListeners(map, 'click')

  }

  const handleUndo = () => {
    setCreating((prev: ICreating) => {
      const copy = structuredClone(prev)
      copy.path.pop()
      copy.areaSize = Number(
        computeArea(copy.path.map((i: ILatLong) => new LatLng(i.lat, i.lng, false))).toFixed(4),
      )
      currentlyCreatingPolygon.current?.setMap(null)
      currentlyCreatingPolygon.current = new google.maps.Polygon({
        paths: copy.path,
        fillColor: '#fff',
        map,
        strokeWeight: 0.5,
      })
      currentlyAddingMarkers.current[currentlyAddingMarkers.current.length - 1].setMap(null)
      currentlyAddingMarkers.current.pop()
      return copy
    })
  }

  const handleCancel = () => {
    setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
    setCurrentlyAdding({ lat: null, lng: null })

    currentlyAddingMarkers.current.forEach(m => m.setMap(null))
    currentlyAddingMarkers.current = []

    if (currentlyCreatingPolygon.current) {
      currentlyCreatingPolygon.current.setMap(null)
      currentlyCreatingPolygon.current = null
    }

    setMenuOpen(false)
    google.maps.event.clearListeners(map, 'click')
  }

  const handleAreasToggle = () => {
    if (toggleAreaControls) {
      google.maps.event.clearListeners(map, 'click')
      setCurrentlyAdding({ lat: null, lng: null })
      setMenuOpen(false)
      currentlyCreatingPolygon.current?.setMap(null)
      currentlyAddingMarkers.current.forEach(m => m.setMap(null))
      currentlyAddingMarkers.current = []
    }
    if (!filters.areas) handleToggle('areas')
    setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
    setToggleAreaControls((prev) => !prev)
  }

  const handleGetInfo = (i) => {
    setCreating({ ...i, currentlyCreating: true, updating: true })
    setMenuOpen(true)
    currentlyAddingMarkers.current = i.path.map(i => new google.maps.Marker({
      position: { lat: i.lat, lng: i.lng },
      map,
    }))
    setToggleAreaControls(true)
  }

  return (
    <>
      <NavBar placeholder='Search employee, machine...' input />
      <PageWrapper noFooter>
        <GlobalViewControls>
          <Flex gap='1rem'>
            <GlobalViewToggle onClick={() => handleToggle('users')} active={filters.users}>
              {UserIcon}
            </GlobalViewToggle>
            <GlobalViewToggle onClick={() => handleToggle('machines')} active={filters.machines}>
              {TruckIcon}
            </GlobalViewToggle>
            <GlobalViewToggle onClick={() => handleToggle('areas')} active={filters.areas}>
              {FoldMapIcon}
            </GlobalViewToggle>
            <GlobalViewSelect
              tabIndex={1}
              onBlur={() => setSelect((prev) => ({ ...prev, open: false }))}
              onClick={() => setSelect((prev) => ({ ...prev, open: !prev.open }))}
            >
              <Flex width='100%' between center>
                {select.selected} <Chevron />
              </Flex>
              {select.open && (
                <GlobalViewOptionsContainer>
                  <GlobalViewSelectOption onClick={(e) => handleSelect(e, 'ALL')}>
                    ALL
                  </GlobalViewSelectOption>
                  <GlobalViewSelectOption onClick={(e) => handleSelect(e, 'ON TASK')}>
                    ON TASK
                  </GlobalViewSelectOption>
                  <GlobalViewSelectOption onClick={(e) => handleSelect(e, 'IDLE')}>
                    IDLE
                  </GlobalViewSelectOption>
                </GlobalViewOptionsContainer>
              )}
            </GlobalViewSelect>
          </Flex>
          <GlobalViewToggle active={false} onClick={handleAreasToggle}>
            {SettingsIcon}
          </GlobalViewToggle>
        </GlobalViewControls>
        <Flex width='100%' end='true' >
          <GlobalViewMobileMenu onClick={() => setMobileOptions(p => !p)} >
            {Hamburger}
            <GlobalViewMobileOptions open={mobileOptions} >
              <Flex gap='1rem' >
                <GlobalViewToggle onClick={() => handleToggle('users')} active={filters.users}>
                  {UserIcon}
                </GlobalViewToggle>
                <GlobalViewToggle onClick={() => handleToggle('machines')} active={filters.machines}>
                  {TruckIcon}
                </GlobalViewToggle>
                <GlobalViewToggle onClick={() => handleToggle('areas')} active={filters.areas}>
                  {FoldMapIcon}
                </GlobalViewToggle>
              </Flex>
              <Flex width='100%' gap='1rem' >

                <GlobalViewSelect
                  tabIndex={1}
                  onBlur={() => setSelect((prev) => ({ ...prev, open: false }))}
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelect((prev) => ({ ...prev, open: !prev.open }))
                  }}
                >
                  <Flex width='100%' between center>
                    {select.selected} <Chevron />
                  </Flex>
                  {select.open && (
                    <GlobalViewOptionsContainer>
                      <GlobalViewSelectOption onClick={(e) => handleSelect(e, 'ALL')}>
                        ALL
                      </GlobalViewSelectOption>
                      <GlobalViewSelectOption onClick={(e) => handleSelect(e, 'ON TASK')}>
                        ON TASK
                      </GlobalViewSelectOption>
                      <GlobalViewSelectOption onClick={(e) => handleSelect(e, 'IDLE')}>
                        IDLE
                      </GlobalViewSelectOption>
                    </GlobalViewOptionsContainer>
                  )}
                </GlobalViewSelect>
                <GlobalViewToggle active={false} onClick={handleAreasToggle}>
                  {SettingsIcon}
                </GlobalViewToggle>
              </Flex>

            </GlobalViewMobileOptions>
          </GlobalViewMobileMenu>
        </Flex>
        <div className='App-map'>
          {toggleAreaControls && (
            <MapHTMLComponentsOverlay globalView>
              {creating.currentlyCreating && (
                <AreaInputs
                  setCreating={setCreating}
                  creating={creating}
                  handleCancel={handleCancel}
                />
              )}
              <ZonesSideMenu>
                <ZonesOrangeCircle onClick={handleMapType}>
                  <WorldIcon />
                </ZonesOrangeCircle>

                {!menuOpen ? (
                  <ZonesOrangeCircle onClick={handleMenuOpen}>{PlusIcon}</ZonesOrangeCircle>
                ) : (
                  <Flex column alignEnd gap='1rem'>
                    <Flex gap='1rem' padding='0 1rem'>
                      {creating.updating && (
                        <ZonesOrangeCircle whiteIcon onClick={handleDeleteArea}>
                          {DeleteIcon}
                        </ZonesOrangeCircle>
                      )}
                      {currentlyCreatingPolygon.current && creating.path.length > 0 &&
                        <ZonesOrangeCircle onClick={handleUndo}>{RestartIcon}</ZonesOrangeCircle>}
                      <ZonesOrangeCircle onClick={handleCancel}>
                        <CloseIcon white />
                      </ZonesOrangeCircle>
                      <ZonesOrangeCircle onClick={handleDone}>{<Check white />}</ZonesOrangeCircle>
                    </Flex>
                    <LatLongContainer>
                      <Input
                        labelText='Latitude'
                        placeholder='Type here'
                        type='number'
                        name='lat'
                        width='8rem'
                        nomarg
                        value={currentlyAdding.lat}
                        onChange={handleEnterCoords}
                        invalid={checkInvalid(invalidFieldsCoords, 'lat')}
                      />
                      <Input
                        nomarg
                        labelText='Longitude'
                        placeholder='Type here'
                        name='lng'
                        type='number'
                        width='8rem'
                        value={currentlyAdding.lng}
                        onChange={handleEnterCoords}
                        invalid={checkInvalid(invalidFieldsCoords, 'lng')}
                      />
                      <ZonesOrangeCircle onClick={handleAddCoords}>{PlusIcon}</ZonesOrangeCircle>
                    </LatLongContainer>
                  </Flex>
                )}
              </ZonesSideMenu>
            </MapHTMLComponentsOverlay>
          )}
          <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY} version='beta' libraries={['marker']}>
            <GlobalViewMap
              usersCluster={usersCluster}
              machinesCluster={machinesCluster}
              loadedPolygons={loadedPolygons}
              filters={filters}
              map={map}
              setMap={setMap}
              select={select}
              handleGetInfo={handleGetInfo}
            />
          </Wrapper>
        </div>
      </PageWrapper>
    </>
  )
}

