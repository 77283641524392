import React, { useEffect, useState } from 'react'
import { Logo, Flex } from '../../styling/GlobalStyles'
import {
  SidebarWrapper,
  SidebarBtnContainer,
  SidebarBtn,
  SideBarList,
  SideBarOption,
  SidebarFooter,
} from './Sidebar.styled'
import { ILink, linksAdmin, linksClient } from '../../constants/links'
import { useNavigate } from 'react-router-dom'
import { Headphones } from '../../assets/icons/Headphones'
import Chevron from '../../assets/icons/Chevron'
import useAuth from '../../hooks/useAuth'
import { v4 } from 'uuid'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSideBar } from 'features/global/globalSlice'
import { RootState } from 'store'

const Sidebar = React.memo(function Sidebar() {
  const { isSidebarOpen } = useSelector((store: RootState) => store.globals)
  const { user } = useSelector((state: RootState) => state.user)
  const { isAdmin } = useAuth()
  const [links, setLinks] = useState<ILink[]>([])
  const navigate = useNavigate()
  const pathname = `/${location.pathname.split('/')[1]}`
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAdmin) {
      setLinks(linksAdmin)
    } else {
      setLinks(linksClient.filter(l => user?.role?.permissions[l.abr]))
    }
  }, [])


  return (
    <>
      <SidebarWrapper sidebar={isSidebarOpen}>
        <Logo />
        <div className='icon' onClick={() => dispatch(toggleSideBar())}>
          <CloseIcon />
        </div>
        <SidebarBtnContainer>
          <section>
            {links.filter(l => l.text !== process.env.REACT_APP_DEVELOPMENT_PAGES).map((item) => {
              const { text, icon, list } = item
              return (
                <div key={v4()} style={{ width: '100%' }}>
                  <SidebarBtn
                    key={v4()}
                    onClick={() => {
                      if (screen.availWidth < 500) {
                        dispatch(toggleSideBar())
                      }
                      navigate(`${item.path}`)
                    }}
                    active={
                      (pathname == '/' && text == 'employees') ||
                      pathname === item.path ||
                      pathname.includes(item.path.split('-')[0])
                    }
                  >
                    <Flex gap='1rem' center>
                      <span>{icon}</span>
                      <p>{text}</p>
                    </Flex>
                    {list && (
                      <div className='chev'>
                        <Chevron
                          sidebar
                        // active={
                        //   pathname === item.path || pathname.includes(item.path.split('-')[0])
                        // }
                        />
                      </div>
                    )}
                  </SidebarBtn>
                  {list && (pathname === item.path || pathname.includes(item.path.split('-')[0])) && (
                    <SideBarList>
                      {list.map((i) => {
                        return (
                          <SideBarOption
                            onClick={() => navigate(`${i.path}`)}
                            active={pathname === i.path}
                            key={v4()}
                          >
                            {i.name}
                          </SideBarOption>
                        )
                      })}
                    </SideBarList>
                  )}
                </div>
              )
            })}
          </section>
        </SidebarBtnContainer>
        <SidebarFooter>{Headphones} Contact Support</SidebarFooter>
      </SidebarWrapper>
    </>
  )
})

export default Sidebar
